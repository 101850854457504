import React from 'react';
import {
  StyledFooter,
  FooterInfo,
  Address,
  PoweredBy,
  Copyright,
  LogosContainer
} from './styled';

const Footer = ({ exhibition, noPadding, assetsPath }) => {
    const { footer_text, footer_phone, footer_credits, footer_website_url, footer_website_text, footer_mail } = exhibition;
    const texts = footer_text ? footer_text.split(';') : [];
    const phones = footer_phone ? footer_phone.split(';') : [];
    const websites = footer_website_url ? footer_website_url.split(';') : [];
    const websitesNames = footer_website_text ? footer_website_text.split(';') : [];
    const footerMails = footer_mail ? footer_mail.split(';') : [];

    return (
        <StyledFooter noPadding={noPadding}>
            <FooterInfo>
                <LogosContainer>
                  <a href="https://galerieimperialart.com/en/imperialart-en/" target="_blank">
                    <img src={`${assetsPath}/imperial-art-logotype.png`} alt="Imperial art logotype"/>
                  </a>
                </LogosContainer>
                <Address>
                    {texts.map((text) => (
                    <pre key={text}>
                      {text.trim()}
                    </pre>
                    ))}
                </Address>
                <Address>
                {phones.map((phone) => (
                    <pre key={phone}>
                      {phone ? `Tel. ${phone.trim()}` : null}
                    </pre>
                ))}
                {footerMails.map((email) => (
                  <pre key={email}>
                    <a href={`mailto:${email}`}>{email}</a>
                  </pre>
                ))}
                {websites.map((websiteURL, index) => (
                  <pre key={websiteURL}>
                    <a href={websiteURL} target="_blank">{websitesNames[index] ? websitesNames[index] : websiteURL}</a>
                  </pre>
                ))}
                <p>{footer_credits}</p>
                </Address>
                <PoweredBy>
                    <span>Powered by</span>
                    <a href="https://arteia.com/">
                      <img src={`${assetsPath}/logo_arteia.svg`} alt="Powered by Arteia"/>
                    </a>
                </PoweredBy>
            </FooterInfo>
            <Copyright>
                <p>&copy; {new Date().getFullYear()}</p>
            </Copyright>
        </StyledFooter>
    );
};

export default Footer;
